import React, { Component } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { serverURL } from "../.././../mainURL";
import {openModal,closeModal} from '../../../functions';

class ResetPassowrd extends Component {
    state={
        pwd:'',
        cpwd:'',
        code:''
    }

    reset = ()=>{
        let pwd = this.state.pwd
        let cpwd = this.state.cpwd
        let code = this.state.code
        if (pwd != cpwd) {
            toast.error("Passwords don't match.")
            return ;            
        }
        if (code.trim() == '') {
            toast.error("Code is required.")
            return ;     
        }
        let data = JSON.parse(localStorage.getItem('reset_password'))
        data['pwd']=pwd.trim();
        data['cpwd']=cpwd.trim();
        data['code']=code.trim();
        console.log(data);
        axios.post(`${serverURL}/api/reset-password`,{data}).then(e=>{
        if (e.data.msg) {
          toast.success(e.data.msg)          
          openModal('#login-modal')
          closeModal('#forget-password-modal')
        }
        document.getElementById('close-reset-password-modal').click()
        }).catch(e=>{                        
          toast.error("Error: Please check code.")            
        })
        // alert(pwd)
        // alert(cpwd)
    }
    render() {
        return (
          
      <div>
        <div className="modal fade" id="reset-password-modal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content border-r-0">
              <div className="modal-header">
              
                <h5 className="modal-title">Reset Password</h5>
                <button
                  type="button"
                  id="close-reset-password-modal"
                 
                  className="close"
                  data-dismiss="modal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="w-100 row p-2">
                  <div className="col-12">
                    <label>Enter code sent to your email</label>
                    <input
                      className="form-control border-r-0"
                      type="text"
                      onChange={(e) => {
                        this.state.code = e.target.value;
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <label>New password</label>
                    <input
                      className="form-control border-r-0"
                      type="password"
                      onChange={(e) => {
                        this.state.pwd = e.target.value;
                      }}
                    />
                  </div>
                     <div className="col-12">
                    <label>Confirm password</label>
                    <input
                      className="form-control border-r-0"
                      type="password"
                      onChange={(e) => {
                        this.state.cpwd = e.target.value;
                      }}
                    />
                  </div>

                  <div className="col-12">
                    <button
                      className="btn btn-primary btn-main border-r-0 btn-md mt-3 d-block mx-auto"
                      onClick={(e) => {
                        this.reset();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="col-12 d-flex justify-content-between alig-items-center">
                    <a
                      className="nav-link text-primary"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#forget-password-modal"
                      style={{fontWeight:'700'}}
                    >
                      BACK
                    </a>
                    <a className="nav-link text-primary"> </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
            </div>
        );
    }
}

export default ResetPassowrd;
