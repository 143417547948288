import React, { Component, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { serverURL } from "../../../mainURL";
import "react-toastify/dist/ReactToastify.css";
// REDUX
import { connect } from "react-redux";
// jquery

class LoginModal extends Component {
  constructor(props) {
    super(props);
    // login-modal
    // modal - title
  }
  state = {
    email: "",
    password: "",
    redirect: false,
  };

  login = async () => {
    let email = this.state.email.trim();
    let pwd = this.state.password.trim();
    let emailInput = document.querySelector('#login-modal input[type="email"]');
    let passwordlInput = document.querySelector(
      '#login-modal input[type="password"]'
    );
    const validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    };
    let postData = { email, pwd };
    if (email == "" || pwd == "") {
      toast.error("All fields are required.");
      emailInput.style.borderColor = "red";
      passwordlInput.style.borderColor = "red";
    } else if (email) {
      if (validateEmail(email)) {
        emailInput.style.borderColor = "green";
        axios
          .post(`${serverURL}/api/login`, postData)
          .then((e) => {
            if (e.data.access_token) {
              localStorage.setItem("token", e.data.access_token);
              localStorage.setItem("userJimatech24", JSON.stringify(e.data));
              emailInput.style.borderColor = "green";
              passwordlInput.style.borderColor = "green";
              // window.location.href = "/dashboard";
              // useHistory.push("/dashboard");
              this.setState({ redirect: true });
            }
          })
          .catch((e) => {
            if (e) {
              console.log(e);
              if (e.response.status == 401) {
                emailInput.style.borderColor = "red";
                passwordlInput.style.borderColor = "red";
                toast.error(
                  `The credential entered does not match our records.`
                );
              }
            }
          });
      } else {
        toast.error("Email invalid.");
        emailInput.style.borderColor = "red";
        return;
      }
    }
  };

  render() {
    const { redirect } = this.state;

    if (redirect) {
      document.querySelectorAll(".modal-backdrop").forEach((e) => {
        e.remove();
      });
      window.location.reload();
      // return <Redirect to="/" />;
    }
    return (
      <div>
        <div className="modal fade" id="login-modal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content border-r-0">
              <div className="modal-header">
                <h5 className="modal-title">Login</h5>
                {/* <h5 className="modal-title">{}</h5> */}
                <button type="button" className="close" data-dismiss="modal">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="w-100 row p-2">
                  <div className="col-12">
                    <label>Email</label>
                    <input
                      className="form-control border-r-0"
                      type="email"
                      value={this.state.email}
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <label>Password</label>
                    <input
                      className="form-control border-r-0"
                      type="password"
                      value={this.state.password}
                      onChange={(e) => {
                        this.setState({ password: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-primary btn-main border-r-0 btn-md mt-3 d-block mx-auto"
                      onClick={(e) => {
                        this.login();
                      }}
                    >
                      Login
                    </button>
                  </div>
                  <div className="col-12 d-flex justify-content-between alig-items-center">
                    <a
                      className="nav-link text-primary"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#register-modal"
                      style={{fontWeight:'700'}}
                    >
                      CREATE ACCOUNT
                    </a>
                    <a
                      className="nav-link text-primary"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#forget-password-modal"
                      style={{fontWeight:'700'}}
                    >
                      FORGOT PASSWORD{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return { globalState: state };
};
// const mapDispatchToProps = () => {};
export default connect(mapStateToProps)(LoginModal);
