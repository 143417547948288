import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Dashborad extends Component {
  render() {
    return (
      <div>
        <h1>This Dashboard</h1>
      </div>
    );
  }
}

export default Dashborad;
