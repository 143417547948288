import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { serverURL } from "../../../mainURL";
 
import {get,getAll,openModal,closeModal} from '../../../functions';
import "react-toastify/dist/ReactToastify.css";

class RegisterModal extends Component {
  state = {
    fname: "",
    lname: "",
    email: "",
    pwd: "",
    cpwd: "",
    code: "",
    codeField:'',
    redirect: false,
  };
  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  register = async () => {
    let fname = this.state.fname.trim();
    let lname = this.state.lname.trim();
    let email = this.state.email.trim();
    let pwd = this.state.pwd.trim();
    let cpwd = this.state.cpwd.trim();
    let fnameInput = document.querySelector("#fnameInput");
    let lfnameInput = document.querySelector("#lfnameInput");
    let emailInput = document.querySelector("#emailInput");
    let pwdInput = document.querySelector("#pwdInput");
    let cpwdInput = document.querySelector("#cpwdInput");
    let postData = { fname, lname, email, pwd, cpwd };

    if (fname == "") {
      toast.error("First name is required.");
      fnameInput.style.borderColor = "red";
      return;
    } else {
      fnameInput.style.borderColor = "green";
    }
    if (lname == "") {
      toast.error("Last name is required.");
      lfnameInput.style.borderColor = "red";
      return;
    } else {
      lfnameInput.style.borderColor = "green";
    }
    if (email == "") {
      toast.error("Email is required.");
      emailInput.style.borderColor = "red";
      return;
    } else {
      if (this.validateEmail(email)) {
        emailInput.style.borderColor = "green";
      } else {
        toast.error("Email is invalid.");
        emailInput.style.borderColor = "red";
        return;
      }
    }
    if (pwd == "") {
      toast.error("Password  is required.");
      pwdInput.style.borderColor = "red";
      return;
    }
    if (cpwd != pwd) {
      toast.error("Passwords don't match.");
      cpwdInput.style.borderColor = "red";
      return;
    } else {
      pwdInput.style.borderColor = "green";
      cpwdInput.style.borderColor = "green";
    }

    let res = await axios.post(`${serverURL}/api/generate/code/register`,{email});
    console.log(res.data);
    if (res.data.code) {
     this.setState({ code: res.data.code });
      toast.warning("We sent a code to your email. Please enter the code below to complete your registration.");
      openModal('#register-code-modal')
    }
   
  };

  createUser =async ()=>{
    let fname = this.state.fname.trim();
    let lname = this.state.lname.trim();
    let email = this.state.email.trim();
    let pwd = this.state.pwd.trim();
    let cpwd = this.state.cpwd.trim();
    let postData = { fname, lname, email, pwd, cpwd };
    let res = await axios.post(`${serverURL}/api/register`,postData);
     if (res.data.access_token) {
      localStorage.setItem("token", res.data.access_token);
      localStorage.setItem("user", JSON.stringify(res.data));
      closeModal("#register-code-modal")
      closeModal("#register-modal")
      openModal("#login-modal")             
    }
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      document.querySelectorAll(".modal-backdrop").forEach((e) => {
        e.remove();
      });
      return <Redirect to="/dashboard" />;
    }
    return (
      <div>
        <div className="modal fade" id="register-modal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content border-r-0">
              <div className="modal-header">
                <h5 className="modal-title">Register</h5>
                <button type="button" className="close" data-dismiss="modal">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="w-100 row p-2">
                  <div className="col-12">
                    <label>First Name</label>
                    <input
                      className="form-control border-r-0"
                      type="text"
                      id="fnameInput"
                      onChange={(e) => {
                        this.state.fname = e.target.value;
                      }}
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <label>Last Name</label>
                    <input
                      className="form-control border-r-0"
                      type="text"
                      id="lfnameInput"
                      onChange={(e) => {
                        this.state.lname = e.target.value;
                      }}
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <label>Email</label>
                    <input
                      className="form-control border-r-0"
                      type="email"
                      id="emailInput"
                      onChange={(e) => {
                        this.state.email = e.target.value;
                      }}
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <label>Password</label>
                    <input
                      className="form-control border-r-0"
                      type="password"
                      id="pwdInput"
                      onChange={(e) => {
                        this.state.pwd = e.target.value;
                      }}
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <label>Confirm password</label>
                    <input
                      className="form-control border-r-0"
                      type="password"
                      id="cpwdInput"
                      onChange={(e) => {
                        this.state.cpwd = e.target.value;
                      }}
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <button
                      className="btn btn-primary btn-main border-r-0 btn-md mt-3 d-block mx-auto"
                      onClick={(e) => {
                        this.register();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="col-12 d-flex justify-content-between alig-items-center">
                    <a
                      className="nav-link text-primary"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#login-modal"
                      style={{fontWeight:'700'}}
                    >
                      ALREADY HAVE AN ACCOUNT?
                    </a>
                    <a className="nav-link text-primary"> </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


 <div className="modal fade" id="register-code-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content border-r-0">
            <div className="modal-header">
              <h5 className="modal-title">Complete Registration</h5>
              <button
                type="button"
                id="close-forget-password-modal"
                className="close"
                data-dismiss="modal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="w-100 row p-2">
                <div className="col-12">
                  <label>Please Enter Code We Sent to Your Email</label>
                  <input
                      className="form-control border-r-0"
                      type="text"
                      onChange={(e) => {
                        this.state.codeField = e.target.value;
                      }}
                    />
                  </div>

                  <div className="col-12">
                    <button
                      className="btn btn-primary btn-main border-r-0 btn-md mt-3 d-block mx-auto"
                      onClick={(e) => {
                        if (this.state.code == this.state.codeField) {
                          this.createUser();
                        }else{
                          toast.error("Invalid Code.");
                        }
                      }}
                    >
                      Submit
                    </button>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default RegisterModal;
