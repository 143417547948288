// const deleteElements = (class) => {
//     document.querySelectorAll(class)
// }

export const hideElements = (cls) => {
  document.querySelectorAll(cls).forEach((e) => {
    e.style.display = "none";
  });
};
export const get = (e) => {
  return document.querySelector(e);
};

export const getAll = (e) => {
  return document.querySelectorAll(e);
};

export const openModal = (modal) => {
  let a = document.createElement("button");
  a.setAttribute("data-toggle", "modal");
  a.setAttribute("data-target", modal);
  document.body.appendChild(a);
  a.click();
  a.remove();
};
export const closeModal = (modal) => {
  let m = document.querySelector(`${modal} .modal-content`);
  let close = document.createElement("button");
  close.setAttribute("data-dismiss", "modal");
  m.appendChild(close);
  close.click();
  close.remove();
};

