import React, { useState, useEffect } from "react";
import { get, getAll } from "../../functions";
import { serverURL } from "../../mainURL";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import StripForm from "./StripForm";
import StripForm2 from "./StripForm2";
// REDUX
import { connect } from "react-redux";
// -

import { send_amount_action } from "../../store/actions/actions";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Publishable_key } from "../../strip_key";
import $ from "jquery";

const promise = loadStripe(Publishable_key);

const ElectricityTopUp = (props) => {
  const [User, setUser] = useState(0);
  const [TransActionFee, setTransActionFee] = useState(0);
  const [TransActionFee2, setTransActionFee2] = useState(0);
  const [Total, setTotal] = useState(0);
  const [YourRecipientReceives, setYourRecipientReceives] = useState(0);
  const [SendAmount, setSendAmount] = useState();
  const [Countrys, setCountrys] = useState([]);
  const [GMDtoUSD, setGMDtoUSD] = useState(0);
  const [GMDtoEUR, setGMDtoEUR] = useState(0);
  const [GMDtoGBP, setGMDtoGBP] = useState(0);
  const [Provider, setProvider] = useState("NAWEC");
  const [Phonenumber, setPhonenumber] = useState();
  const [ReceiverFirstName, setReceiverFirstName] = useState();
  const [City, setCity] = useState();
  const [CountryCode, setCountryCode] = useState("+220");
  const [MeterReading, setMeterReading] = useState();
  const [ReceiveAmount, setReceiveAmount] = useState(0);
  const [ReceiverMiddleName, setReceiverMiddleName] = useState();
  const [ReceiverLastName, setReceiverLastName] = useState();
  const [Country, setCountry] = useState("Gambia");
  const [Step, setStep] = useState(0);
  const [Type, setType] = useState(1);
  const [BUYER_DATA, setBUYER_DATA] = useState({});
  
  useEffect(() => {
    get("#btn-shop-now").addEventListener("click",()=>{
      setStep(0)
    })
        
    setUser(JSON.parse(localStorage.getItem("userJimatech24")));

    // get("#receive-amount-input").value = get("#select-currency-navbar").value;
    if (get("#send-amount-currncey")) {
      get("#send-amount-currncey").innerHTML = get(
        "#select-currency-navbar"
      ).value;
    }
    getAll(".currency").forEach((e) => {
      e.innerHTML = get("#select-currency-navbar").value;
    });

    axios.post(`${serverURL}/api/get/setting`, {token: localStorage.getItem("token")}).then((res) => {
      // console.log(res);
      let data = res.data[0];
      setGMDtoUSD(data.gmd_to_usd);
      setGMDtoEUR(data.gmd_to_euro);
      setGMDtoGBP(data.gmd_to_gbp);
      setTransActionFee(data.transaction_fee);
    });
    // /setCountry
    axios.get(`${serverURL}/api/get/country`).then((e) => {
      setCountrys(e.data);
      // console.log(e.data);
    });

    get("#select-currency-navbar").addEventListener("change", (e) => {
      let value = e.target.value;
      // get("#send-amount-input").value=0;
      // get("#receive-currncey").innerHTML = value;
      if (get("#send-amount-currncey")) {
        get("#send-amount-currncey").innerHTML = value;
      }
      getAll(".currency").forEach((e) => {
        e.innerHTML = value;
      });
    });
  }, []);

  get("#select-currency-navbar").addEventListener("change", () => {
    setSendAmount('');
    setYourRecipientReceives(0);
    setTransActionFee2(0);
    setTotal(0);
  });

  useEffect(() => {
    let value = get("#select-currency-navbar").value;
    setTotal(parseFloat(SendAmount) + parseFloat(TransActionFee2));
    // console.log(value);
    let toEUR = SendAmount * GMDtoEUR;
    let toEURTotal = Total * GMDtoEUR;
    let toUSD = SendAmount * GMDtoUSD;
    let toUSDTotal = Total * GMDtoUSD;
    let toGBP = SendAmount * GMDtoGBP;
    let toGBPTotal = Total * GMDtoGBP;

    if (value == "EUR") {
      setReceiveAmount(isNaN(toEUR) ? 0 : parseFloat(toEUR));
      setYourRecipientReceives(isNaN(toEURTotal) ? 0 : parseFloat(toEURTotal));
    }
    if (value == "USD") {
      setReceiveAmount(isNaN(toUSD) ? 0 : parseFloat(toUSD));
      setYourRecipientReceives(isNaN(toUSDTotal) ? 0 : parseFloat(toUSDTotal));
    }
    if (value == "GBP") {
      setReceiveAmount(isNaN(toGBP) ? 0 : parseFloat(toGBP));
      setYourRecipientReceives(isNaN(toGBPTotal) ? 0 : parseFloat(toGBPTotal));
    }
  }, [SendAmount]);

  const paymentPage = async () => {
    if (ReceiverFirstName == "" || typeof ReceiverFirstName == 'undefined') {
      toast.error("First name is required.");
      get("#e-r-first-name").style.borderColor = "red";
      return;
    } else {
      get("#e-r-first-name").style.borderColor = "green";
    }
    if (ReceiverLastName == "" || typeof ReceiverLastName == 'undefined') {
      toast.error("Last name is required.");
      get("#e-r-last-name").style.borderColor = "red";
      return;
    } else {
      get("#e-r-last-name").style.borderColor = "green";
    }
    if (City == "" || typeof City == 'undefined') {
      toast.error("City is required.");
      get("#e-city").style.borderColor = "red";
      return;
    } else {
      get("#e-city").style.borderColor = "green";
    }
    if (SendAmount == "" || typeof SendAmount == 'undefined') {
      toast.error("Send amount is required.");
      get("#send-amount-input").style.borderColor = "red";
      return;
    } else {
      get("#send-amount-input").style.borderColor = "green";
    }
    if (Phonenumber == "" || typeof Phonenumber == 'undefined') {
      toast.error("Phone number is required.");
      get("#e-phone-number").style.borderColor = "red";
      return;
    } else {
      get("#e-phone-number").style.borderColor = "green";
    }
    if (Phonenumber.length != 7 || isNaN(Phonenumber)) {
      toast.error("Invalid Phone number.");
      get("#m-phone-number").style.borderColor = "red";
      return;
    } else {
      get("#m-phone-number").style.borderColor = "green";
    }        
    if (MeterReading == "" || typeof MeterReading == 'undefined') {
      toast.error("Meter number is required.");
      get("#e-meter-reading").style.borderColor = "red";
      return;
    } else {
      get("#e-meter-reading").style.borderColor = "green";
    }
    if (isNaN(MeterReading)) {
      toast.error("Invalid Meter number.");
      get("#e-meter-reading").style.borderColor = "red";
      return;
    } else {
      get("#e-meter-reading").style.borderColor = "green";
    }    
    if (Type == "") {
      toast.error("Type is required.");
      return;
    }
    if (User.id == "") {
      toast.error("User id  is required.");
      return;
    }
    if (User.email == "") {
      toast.error("Email is required.");
      return;
    }

    get("#continueBtn").innerText = 'Please wait...';
    get("#continueBtn").disabled = true;

    //Validate Meter Number
    await axios.post(`${serverURL}/api/transaction/validate-meter`, 
      {
        meterNumber: MeterReading,
        phone: Phonenumber,
        amount: SendAmount,
        currency: get("#select-currency-navbar").value,
        token: localStorage.getItem("token") || false
      }
    ).then((res) => {
      //console.log(res); return false;
      if(res.data.type == 'error'){
        toast.error(res.data.message);
        get("#continueBtn").innerText = 'Continue';
        get("#continueBtn").disabled = false;        
        return false;
      }else{
        setStep(1);
        setBUYER_DATA({
          ReceiverFirstName,
          ReceiverMiddleName,
          ReceiverLastName,
          Country,
          City,
          Provider,
          ReceiveAmount,
          Phonenumber,
          CountryCode,
          MeterReading,
          CountryCode,
          Type,
          user_id: User.id,
          user_email: User.email,
        });
        props.send_amount_action(Total);  
        get("#continueBtn").innerText = 'Continue';
        get("#continueBtn").disabled = false;               
      }
    }); 
        
  };

  const agreeTerms = () => {
    $(".hide_sections").slideUp();
    $(".TERMS_section").slideDown();
  }

  return (
    <div className="ElectricityTopUp_section hide_sections   ">
      <div className="row w-100" style={{ padding: "0% 10%" }}>
        <div className="col-12 text-center">
          <h5>Electricity Top-Up</h5>
        </div>
        {Step == 1 ? (
          <Elements stripe={promise}>
            <StripForm BUYER_DATA={BUYER_DATA} />
            {/* <StripForm2 BUYER_DATA={BUYER_DATA} /> */}
          </Elements>
        ) : (
          ""
        )}
        {Step == 0 ? (
          <div className="col-12 text-left">
            <p>
              <strong style={{ color: "red" }}>Attention:</strong> Please review
              the recipient phone or meter number for accuracy.
              Failure to provide the correct phone or meter number will lead to
              loss of credit. All transactions are irreversible.
            </p>
          </div>
        ) : (
          ""
        )}
        {Step == 0 ? (
          <div className="col-12 col-md-12 col-lg-4 mt-2 mb-2">
            {/* From col 1 */}
            <div className="row">
              <div className="col-12">
                <p className="mb-0 mt-2">
                  <small>Provider*</small>
                </p>
                <select
                  className="form-control "
                  id="e-provider"
                  value={Provider}
                  onChange={(e) => {
                    setProvider(e.target.value);
                  }}
                >
                  <option value="NAWEC" key="" selected>
                    NAWEC
                  </option>
                </select>
              </div>
              <div className="col-12">
                <p className="mb-0 mt-2">
                  <small>Phone Number*</small>
                </p>
                <input
                  type="text"
                  // value={User ? User.phone : 0}
                  id="e-phone-number"
                  className="form-control"
                  value={Phonenumber}
                  onChange={(e) => {
                    setPhonenumber(e.target.value);
                  }}
                />
              </div>
              <div className="col-12  position-r">
                <p className="mb-0 mt-2">
                  <small>Send Amount*</small>
                </p>
                <small className="currncey-input" id="send-amount-currncey">
                  USD
                </small>
                <input
                  type="number"
                  className="form-control"
                  value={SendAmount}
                  id="send-amount-input"
                  onChange={(e) => {
                    let value = e.target.value;                    
                    setSendAmount(value);                   
                    
                    let fees = 2 + value * TransActionFee / 100;
                    setTransActionFee2(fees);
                    
                    let granTotal = parseFloat(value) + parseFloat(fees);                    
                    setTotal(granTotal);
                  }}
                />
              </div>
              <div className="col-12">
                <p className="mb-0 mt-2">
                  <small>Receiver First Name*</small>
                </p>
                <input
                  type="text"
                  id="e-r-first-name"
                  value={ReceiverFirstName}
                  className="form-control"
                  onChange={(e) => {
                    setReceiverFirstName(e.target.value);
                  }}
                />
              </div>
              <div className="col-12">
                <p className="mb-0 mt-2">
                  <small>City*</small>
                </p>
                <input
                  type="text"
                  value={City}
                  id="e-city"
                  className="form-control"
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* From col 2 */}
        {Step == 0 ? (
          <div className="col-12 col-md-12 col-lg-4 mt-2 mb-2">
            <div className="row">
              <div className="col-12">
                <p className="mb-0 mt-2">
                  <small>Country Code*</small>
                </p>
                <input
                  type="text"
                  id="e-country-code"
                  // value={User ? User.country : '+22'}
                  value={CountryCode}
                  className="form-control"
                  onChange={(e) => {
                    setCountryCode(e.target.value);
                  }}
                  readOnly
                />
              </div>
              <div className="col-12">
                <p className="mb-0 mt-2">
                  <small>Meter Number*</small>
                </p>
                <input
                  type="text"
                  id="e-meter-reading"
                  className="form-control"
                  value={MeterReading}
                  onChange={(e) => {
                    setMeterReading(e.target.value);
                  }}
                />
              </div>
              <div className="col-12 position-r">
                <p className="mb-0 mt-2">
                  <small>Receive Amount*</small>
                </p>
                <small className="currncey-input">GMD</small>

                <input
                  type="text"
                  value={ReceiveAmount.toFixed(2)}
                  id="receive-amount-input"
                  readOnly
                  onChange={(e) => {
                    setReceiveAmount(e.target.value);
                  }}
                  className="form-control"
                />
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-6">
                    <p style={{ width: "9rem" }} className="mb-0 mt-2">
                      <small>Receiver Middle Name</small>
                    </p>
                    <input
                      type="text"
                      id="e-r-middle-name"
                      className="form-control"
                      value={ReceiverMiddleName}
                      onChange={(e) => {
                        setReceiverMiddleName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-12 col-lg-6">
                    <p className="mb-0 mt-2">
                      <small>Receiver Last Name*</small>
                    </p>
                    <input
                      type="text"
                      id="e-r-last-name"
                      value={ReceiverLastName}
                      onChange={(e) => {
                        setReceiverLastName(e.target.value);
                      }}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <p className="mb-0 mt-2">
                  <small>Country*</small>
                </p>
                <select
                  className="form-control"
                  disabled={true}
                  id="country-select-input"
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                >
                  {Countrys.map((country) => {
                    if (country.countryname == "Gambia") {
                      return (
                        <option
                          key={country.id}
                          selected
                          value={country.countryname}
                        >
                          {country.countryname}
                        </option>
                      );
                    }
                    return (
                      <option key={country.id} value={country.countryname}>
                        {country.countryname}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="col-12 col-md-12 col-lg-4 mt-2 mb-2">
          <div className="card border border-success w-100  ">
            <div className="card-body px-0 m-0">
              <h5 className="card-title text-center w-100 m-0 p-0 ">
                Order Summary
              </h5>
              <hr className="text-success bg-success " />
              <div
                className="d-flex justify-content-between align-items-center border-bottom  mx-auto"
                style={{ width: "90%" }}
              >
                <p className="mb-1">
                  <strong>Amount to Send</strong>
                </p>
                <p className="mb-1">
                  <strong>
                    {SendAmount || 0} <strong className="currency">USD</strong>
                  </strong>
                </p>
              </div>
              <div
                className="d-flex justify-content-between align-items-center   mx-auto my-5"
                style={{
                  width: "90%",
                  borderBottom: "4px solid #4ba6ff",
                }}
              >
                <p className="mb-1 ">
                  <strong className="text-muted">
                    Transaction Fees
                    {/* <i
                      class="fas fa-question-circle text-muted"
                      style={{ fontSize: "13px" }}
                    ></i> */}
                  </strong>
                </p>
                <p className="mb-1 ">
                  <strong className="text-muted">
                    {TransActionFee2.toFixed(2)}
                     <strong className="currency">&nbsp;USD</strong>
                  </strong>
                </p>
              </div>
              <div
                className="d-flex justify-content-between align-items-center border-bottom mx-auto mt-5"
                style={{ width: "90%" }}
              >
                <p className="mb-1 ">
                  <strong className="">
                    Grand Total
                  </strong>
                </p>
                <p className="mb-1 ">
                  <strong className="">
                    {isNaN(Total.toFixed(2)) ? 0 : Total.toFixed(2)} <strong className="currency">USD</strong>
                  </strong>
                </p>
              </div>
              <div
                className="d-flex justify-content-between align-items-center  mx-auto my-0"
                style={{ width: "90%" }}
              >
                <p className="mb-1 ">
                  <strong className="">Your Recipient Receives&nbsp;</strong>
                </p>
                <p className="mb-1 ">
                  <strong className="">
                    {ReceiveAmount.toFixed(2)} <strong>GMD</strong>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        {Step == 0 ? (
          <div className="col-12 mt-3">
            <button
              className="btn btn-primary btn-main border-r-0"
              id="continueBtn"
              onClick={(e) => {
                paymentPage();
              }}
            >
              Continue
            </button>
          </div>
        ) : (
          ""
        )}
        {Step == 1 ? (
          <div className="col-12 mt-3 p-0">
            <div>
              <input
                id="agree_terms_and_conditiona_e"
                type="checkbox"
                className="mr-1"
              />
              <span className="mr-1">I agree with the </span>
              <span className="text-primary" id="agreeTerms" style={{cursor:'pointer'}}
                onClick={(e) => {
                  agreeTerms();
                }}              
              >
                Terms and conditions
              </span>
            </div>
            <div className="mt-2 ">
              <button
                className="btn btn-outline-primary mr-2"
                onClick={(e) => {
                  setStep(0);
                }}
              >
                <i class="fas fa-chevron-left text-primary"></i> Back to order
              </button>
              <button
                id="submitOrder"
                className="btn btn-success btn-main border-r-0"
                onClick={(e) => {
                  if (get("#agree_terms_and_conditiona_e").checked) {
                    get("#strip_payment_send_btn").click();                    
                  } else {
                    toast.error("You must agree with the Terms and conditions");
                  }
                }}
              >
                Submit 
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const mapStateToPrps = (state) => {
  return { redux: state };
};
//send_amount_action
const mapDispatchToProps = (Dispatch) => {
  return {
    send_amount_action: (newValue) => Dispatch(send_amount_action(newValue)),
  };
};
export default connect(mapStateToPrps, mapDispatchToProps)(ElectricityTopUp);
