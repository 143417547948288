import React, { useContext } from "react";
import Home from "./views/home/Home";
import Dashboard from "./views/dashboard/Dashboard";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// REDUX
import store from "./store/store";
import { Provider } from "react-redux";
import Privacypolicy from "./views/Privacypolicy";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/dashboard" exact component={Dashboard}></Route>
          <Route path="/Privacy/Policy" exact component={Privacypolicy}></Route>
        </Switch>
      </Router>
    </div>
  );
}

function AppWithStore() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}

export default AppWithStore;
