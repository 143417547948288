let url = window.location.origin;
//console.log(url);
if (url.indexOf('local') !== -1) {
  url = "http://jimatech24server.local";
} else {
  url = "https://gamlight24.com/server/server.php";
}
//url = "https://gamlight24.com/server/server.php";

console.log(url)
export const serverURL = url;
