import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { serverURL } from "../../mainURL";
import { get, getAll } from "../../functions";
import "react-toastify/dist/ReactToastify.css";

class UpdateProfile extends Component {
  state = {
    fname: "",
    lname: "",
    email: "",
    phone: "",
    userId: localStorage.getItem("userJimatech24")
      ? JSON.parse(localStorage.getItem("userJimatech24")).id
      : 0,
    pwd: "",
    cpwd: "",
    redirect: false,
  };

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  register = async () => {
    let fname = this.state.fname.trim();
    let lname = this.state.lname.trim();
    let email = this.state.email.trim();
    let phone = this.state.phone;
    let id = this.state.userId;
    let pwd = this.state.pwd.trim();
    let cpwd = this.state.cpwd.trim();
    let fnameInput = document.querySelector("#fnameInput");
    let lfnameInput = document.querySelector("#lfnameInput");
    let emailInput = document.querySelector("#emailInput");
    let pwdInput = document.querySelector("#pwdInput");
    let cpwdInput = document.querySelector("#cpwdInput");
    let postData = { id, fname, lname, email, phone, pwd, cpwd };

    if (fname == "") {
      toast.error("First name is required.");
      fnameInput.style.borderColor = "red";
      return;
    } else {
      fnameInput.style.borderColor = "green";
    }
    if (lname == "") {
      toast.error("Last name is required.");
      lfnameInput.style.borderColor = "red";
      return;
    } else {
      lfnameInput.style.borderColor = "green";
    }
    if (email == "") {
      toast.error("Email is required.");
      emailInput.style.borderColor = "red";
      return;
    } else {
      if (this.validateEmail(email)) {
        emailInput.style.borderColor = "green";
      } else {
        toast.error("Email is invalid.");
        emailInput.style.borderColor = "red";
        return;
      }
    }

    if (cpwd != pwd) {
      toast.error(" Passwords don't match.");
      cpwdInput.style.borderColor = "red";
      return;
    } else {
      pwdInput.style.borderColor = "green";
      cpwdInput.style.borderColor = "green";
    }

    let res = await axios.post(
      `${serverURL}/api/update/user/profile`,
      postData
    );
    if (res.data.access_token) {
      localStorage.setItem("token", res.data.access_token);
      localStorage.setItem("userJimatech24", JSON.stringify(res.data));

      this.setState({ redirect: true });
      toast.success("Profile updated successfully.");
/*       getAll(".show_section").forEach((e) => {
        e.style.display = "block";
      }); */
    }
  };
  async findUser() {
    let res = await axios.post(`${serverURL}/api/find/user`, {
      id: this.state.userId,
    });
    let user = res.data;
    this.setState({
      fname: user.fname,
      lname: user.lname,
      email: user.email,
      phone: user.phone,
    });
  }
  componentDidMount() {
    this.findUser();
  }
  render() {
    const { redirect } = this.state;

    if (redirect) {
      //   document.querySelectorAll(".modal-backdrop").forEach((e) => {
      // e.remove();
      //   });
      //document.querySelector("#update-profile-user-form").style.display ="none";
      //this.setState({ redirect: false });
      //   return <Redirect to="/dashboard" />;
    }
    return (
      <div>
        <div
          className="w-75 mx-auto row p-2"
          id="update-profile-user-form"
          style={{ display: "none" }}
        >
          <div className="col-12 text-center">
            <h5>Update Profile</h5>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-2">
            <label>First name</label>
            <input
              className="form-control border-r-0"
              type="text"
              id="fnameInput"
              value={this.state.fname}
              onChange={(e) => {
                this.setState({ fname: e.target.value });
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-2">
            <label>Last name</label>
            <input
              className="form-control border-r-0"
              type="text"
              id="lfnameInput"
              value={this.state.lname}
              onChange={(e) => {
                this.setState({ lname: e.target.value });
              }}
            />
          </div>
          <div className="col-12 mt-2">
            <label>Email</label>
            <input
              className="form-control border-r-0"
              type="email"
              id="emailInput"
              value={this.state.email}
              readOnly
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
            />
          </div>
          <div className="col-12 mt-2">
            <label>Phone</label>
            <input
              className="form-control border-r-0"
              type="phone"
              id="phoneInput"
              value={this.state.phone}
              onChange={(e) => {
                this.setState({ phone: e.target.value });
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-2">
            <label>Password</label>
            <input
              className="form-control border-r-0"
              type="password"
              placeholder="leave blank if you dont wish to change password"
              id="pwdInput"
              onChange={(e) => {
                this.setState({ pwd: e.target.value });
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-2">
            <label>Confirm password</label>
            <input
              className="form-control border-r-0"
              type="password"
              placeholder="leave blank if you dont wish to change password"
              id="cpwdInput"
              onChange={(e) => {
                this.setState({ cpwd: e.target.value });
              }}
            />
          </div>
          <div className="col-12">
            <button
              className="btn btn-primary btn-main border-r-0 btn-md mt-3 d-block mx-auto"
              onClick={(e) => {
                this.register();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default UpdateProfile;
