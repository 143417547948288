import React, { useState, useEffect } from "react";
import Axios from "axios";
import { serverURL } from "../../mainURL";
import { get, getAll } from "../../functions";
import DataTable from "./DataTable";
// redux
import { connect } from "react-redux";
import { MDBBtn  } from 'mdbreact';
import { toast } from "react-toastify";

const Users = (props) => {
  const [Users, setUsers] = useState([]);
  const [refreshUsers, setRefreshUsers] = useState();  
  async function GetUsers(){
    const Users = await Axios.post(`${serverURL}/api/get/users`, {
      token: localStorage.getItem("token") || false,
    });
    
    if(Users){
      let x = await Users.data.users.forEach((usr) => {
        usr["created_at"] = usr["created_at"].substring(0,10);        
        if(usr["status"] == 1){
          let btnId = `disableUsersBtn${usr["id"]}`;
          usr["status"] = <MDBBtn color="red" rounded size="sm" 
          className="disableUsersBtn"
          id = {btnId}
          onClick = {(e) => {
            disableUser(e);
          }}
          >
            Disable
          </MDBBtn>;
        }else{
          let btnId = `enableUsersBtn${usr["id"]}`;
          usr["status"] = <MDBBtn color="green" rounded size="sm" 
          className="enableUsersBtn"
          id = {btnId}
          onClick = {(e) => {
            enableUser(e);
          }}
          >
            Activate
          </MDBBtn>;          
        }
      });

      setUsers(Users.data.users);
    }
    
  }
      
  useEffect(() => {   
    GetUsers();             
  }, [refreshUsers]); 
  
  const disableUser = async (e) => {

    if(window.confirm("Are you sure you want to disable this user?")){
      let userId = e.target.id.match(/\d+/)[0];
      //console.log(userId);

      const DisableUser = await Axios.post(`${serverURL}/api/change-user-status`, {
        token: localStorage.getItem("token") || false,
        userId: userId,
        userStatus: 0
      });   

      if(DisableUser.data.user){
        setRefreshUsers(Math.random());
        toast.success("User was disabled.");
      }
      
    }

  };

  const enableUser = async (e) => {

    if(window.confirm("Are you sure you want to activate this user?")){
      let userId = e.target.id.match(/\d+/)[0];
      //console.log(userId);

      const EnableUser = await Axios.post(`${serverURL}/api/change-user-status`, {
        token: localStorage.getItem("token") || false,
        userId: userId,
        userStatus: 1
      });   

      if(EnableUser.data.user){
        setRefreshUsers(Math.random());
        toast.success("User was activated.");
      }
      
    }

  };  
 
  const cols = [
    {
      label: "First Name",
      field: "fname",
    },
    {
      label: "Last Name",
      field: "lname",
    },
    {
      label: "Email",
      field: "email",
    },
    {
      label: "Date Registered",
      field: "created_at",
    },
    {
      label: "Action",
      field: "status",
    },    
  ];

  return (
    <div
      id="users_section"
      className="hide_sections px-3"
      style={{ display: "none" }}
    >
      <h5 className="text-center" >Users </h5>
      <div class="table-responsive">
        <DataTable col={cols} row={Users} />
  
      </div>
    </div>
  );
};
const mapStateToPrps = (state) => {
  return { redux: state };
};
export default connect(mapStateToPrps)(Users);
