import React from "react";
import "./cookies.css";
import { get, getAll } from "../../functions";
import { Link } from "react-router-dom";
import $ from "jquery";

const CookiesAlert = () => {
  return (
    <div className=" ">
      <div
        id="cookiesAlert"
        className="cookiesAlert d-flex justify-content-center align-items-center"
      >
        <div className="row p-5">
          <div className="col-md-2"></div>
          <div className="col-md-7">
            <p>
              We use cookies to provide the basic functions of the website. You consent to this by clicking on "I consent"
              or by continuing your use of this website. Further information about
              cookies and how we collect and process your information can be found in our&nbsp;
              <a
                onClick={(e) => {
                  $(".hide_sections").slideUp();
                  $(".privacy_section").slideDown();
                }}
              >
                Privacy Policy
              </a>
              &nbsp;and&nbsp;
              <a
                onClick={(e) => {
                  $(".hide_sections").slideUp();
                  $(".cookies_section").slideDown();
                }}
              >
                Cookies Policy.
              </a>              
            </p>
          </div>
          <div className="col-md-2 text-center">
            <button
              className="btn btn-success"
              onClick={(e) => {
                localStorage.setItem("cookies_yes", true);
                get("#cookiesAlert").remove();
              }}
              style={{fontSize: '0.9rem'}}
            >
              I consent
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesAlert;
