import React from "react";

const Privacypolicy = () => {
  return (
    <div
      className=" d-flex flex-column justify-content-center align-items-center"
      style={{
        height: "100vh",
        background:
          "linear-gradient(141deg, #9fb8ad 0%, #1fc8db 51%, #2cb5e8 75%)",
      }}
    >
      <h1 className="">Privacy Policy</h1>
    </div>
  );
};

export default Privacypolicy;
