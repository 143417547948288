import React, { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { serverURL } from "../../mainURL";

const ContactUs = () => {
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [msg, setmsg] = useState("");
  const sendMsg = () => {
    if (name.trim() == "" || email.trim() == "" || msg.trim() == "") {
      toast.error("All fields are required.");
      return;
    }
    if (!validateEmail(email)) {
      toast.error("Invalid email.");
      return;
    }
    axios
      .post(`${serverURL}/api/send-message`, { name, email, msg })
      .then((e) => {
        toast.success("Message sent. We will get back to you within 24 hours.");
        setname("");
        setemail("");
        setmsg("");
      })
      .catch((e) => {
        toast.error("error");
      });
  };
  return (
    <div>
      <div className="contact-us-section">
        <h1>Contact us </h1>
        <p>
          Questions? Drop us a message and we will get back to you as soon as
          possible
        </p>
        <div className="row p-3">
          <div className="col-12 col-md-6 col-lg-6">
            <input
              placeholder="Name"
              className="form-control mx-auto mb-2 p-3"
              id="name"
              value={name}
              onChange={(e) => {
                setname(e.target.value);
              }}
              type="text"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <input
              placeholder="Email"
              className="form-control mx-auto mb-2 p-3"
              id="email"
              value={email}
              onChange={(e) => {
                setemail(e.target.value);
              }}
              type="email"
            />
          </div>
          <div className="col-12">
            <textarea
              placeholder="Message"
              value={msg}
              onChange={(e) => {
                setmsg(e.target.value);
              }}
              className="form-control mx-auto mb-2 p-3"
              id="msg"
            ></textarea>
          </div>
          <div className="col-12 d-flex justify-conetnt-end">
            <button
              className="btn btn-primary btn-main px-3 py-2  border-r-0 "
              onClick={(e) => {
                sendMsg();
              }}
            >
              Send Message
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
