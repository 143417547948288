import React, { useState, useEffect } from "react";
import { serverURL } from "../../mainURL";
import axios from "axios";
import { toast } from "react-toastify";
import { get, getAll } from "../../functions";
//redux
import { connect } from "react-redux";
import {change_transaction_fee} from '../../store/actions/actions';

const SystemSetting = (props) => {
  const [GMDtoUSD, setGMDtoUSD] = useState(0);
  const [GMDtoEUR, setGMDtoEUR] = useState(0);
  const [GMDtoGBP, setGMDtoGBP] = useState(0);
  const [transactionFee, settransactionFee] = useState(0);

  const getSetting = async () => {

    let res = await axios.post(`${serverURL}/api/get/setting`, {token: localStorage.getItem("token")});

    let data = res.data[0];
    setGMDtoUSD(data.gmd_to_usd);
    setGMDtoEUR(data.gmd_to_euro);
    setGMDtoGBP(data.gmd_to_gbp);
    settransactionFee(data.transaction_fee);

  };
  useEffect(() => {
    getSetting();
  }, []);
  const typeOfNaN = (x) => {
    if (Number.isNaN(x)) {
      return "Number NaN";
    }
    if (isNaN(x)) {
      return "NaN";
    }
    return;
  };
  const changeSetting = async () => {
    if (!parseFloat(GMDtoUSD)) {
      return toast.error("Only numbers are accepted.");
    }
    if (!parseFloat(GMDtoEUR)) {
      return toast.error("Only numbers are accepted.");
    }
    if (!parseFloat(GMDtoGBP)) {
      return toast.error("Only numbers are accepted.");
    }
    if (!parseFloat(transactionFee)) {
      return toast.error("Only numbers are accepted.");
    }
    await axios
      .post(`${serverURL}/api/update/setting`, {
        GMDtoUSD,
        GMDtoEUR,
        GMDtoGBP,
        transactionFee,
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        if (res.data.msg) {
          toast.success("Settings updated successfully.");
          props.change_transaction_fee(transactionFee)
        
/*           getAll(".show_section").forEach((e) => {
            e.style.display = "block";
          }); */
        }
      })
      .catch((res) => {
        // console.log();
        toast.error("Unauthrize");
      });

    //document.querySelector("#setting-section-form").style.display = "none";
  };
  return (
    <div>
      <div
        className="row w-50 mx-auto"
        id="setting-section-form"
        style={{ display: "none" }}
      >
        <div className="col-12 text-center">
          <h5>System Settings</h5>
        </div>
        <div className="col-12 text-center mt-3">
          <h6>USD to GMD</h6>
        </div>
        <div className="col-12 text-center">
          <input
            type="number"
            className="form-control w-100 border-r-0 text-center"
            value={GMDtoUSD}
            onChange={(e) => {
              setGMDtoUSD(e.target.value);
            }}
          />
        </div>
        <div className="col-12 text-center mt-3">
          <h6>EUR to GMD</h6>
        </div>
        <div className="col-12 text-center">
          <input
            type="number"
            className="form-control w-100 border-r-0 text-center"
            value={GMDtoEUR}
            onChange={(e) => {
              setGMDtoEUR(e.target.value);
            }}
          />
        </div>
        <div className="col-12 text-center mt-3">
          <h6>GBP to GMD</h6>
        </div>
        <div className="col-12 text-center">
          <input
            type="number"
            className="form-control w-100 border-r-0 text-center"
            value={GMDtoGBP}
            onChange={(e) => {
              setGMDtoGBP(e.target.value);
            }}
          />
        </div>
        <div className="col-12 text-center mt-3">
          <h6>Transaction Fee (%)</h6>
        </div>
        <div className="col-12 text-center">
          <input
            type="number"
            className="form-control w-100 border-r-0 text-center"
            value={transactionFee}
            id="transactionFeeInput"
            onChange={(e) => {
              settransactionFee(e.target.value);
            }}
          />
        </div>
        <div className="col-12 text-center">
          <button
            className="btn btn-primary  btn-main border-r-0 btn-md mt-3 d-block mx-auto "
            onClick={(e) => {
              changeSetting();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch)=>{
  return {
    change_transaction_fee:(newValue)=>dispatch(change_transaction_fee(newValue))
  }
}
export default connect(null,mapDispatchToProps)(SystemSetting);
