import { CHANGE_TRANSACTION_FEE } from "./type";
import { SEND_AMOUNT_CHANGE } from "./type";
import { TRANSACTION_CREATED } from "./type";

export const change_transaction_fee = (newValue) => {
  return {
    type: CHANGE_TRANSACTION_FEE,
    newValue,
  };
};
export const send_amount_action = (newValue) => {
  return {
    type: SEND_AMOUNT_CHANGE,
    newValue,
  };

};

export const transaction_created = (newValue) => {
  return {
    type: TRANSACTION_CREATED,
    newValue,
  };
}
