import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { get, getAll } from "../../functions";
import { toast } from "react-toastify";
import { serverURL } from "../../mainURL";
import "./StripFormElec.css";
import Axios from "axios";

// REDUX
import { transaction_created } from "../../store/actions/actions";

const StripForm = ({BUYER_DATA,  redux, transaction_created}) => {
  const [User, setUser] = useState(0);
  const elements = useElements();
  const stripe = useStripe();

  const makePayment = async () => {
    let submitOrder = get('#submitOrder');
    submitOrder.disabled = true;
    submitOrder.innerText = 'Please wait...';

    getAll(".submit_strip_from_btn").forEach((e) => {
      e.setAttribute("disabled", "true");
    });
    const cardElement = elements.getElement(CardElement);

    const payment_intent = await Axios.post(`${serverURL}/api/payment-intent`, {
      amount: parseFloat(redux.send_amount.toFixed(2)),
      currency: get("#select-currency-navbar").value,
      token: localStorage.getItem("token") || false,
    })
    .then(async (e) => {

      let clientSecret = e.data.clientSecret;      

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: User.fname+' '+User.lname,
          email: User.email,
          address: {
            city: User.city
          },
        },
      })
      .then(async (e) => {
        //console.log(e.paymentMethod.id);        
        
        if(e.error){
          toast.error(e.error.message);
          submitOrder.innerText = 'Submit'; 
          return false;
        }

        /*console.log(e.paymentMethod.id);
        console.log(clientSecret);*/
        const confiremCardPayment = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: e.paymentMethod.id,
          }
        ).then(async (e) => {
  
          if(e.error){
            toast.error(e.error.message);
            submitOrder.innerText = 'Submit';
            return false;
          }
                  
          //Store transaction in backend only after payment is succesful      
          const processPayment = await Axios.post(`${serverURL}/api/store/transaction`, {
            amount: parseFloat(redux.send_amount.toFixed(2)),
            currency: get("#select-currency-navbar").value,
            BUYER_DATA,
            token: localStorage.getItem("token") || false
          }).then( (res) => {     
            if(res.data.type == 'error'){
              toast.error(res.data.message);
              submitOrder.disabled = false;
              submitOrder.innerText = 'Submit';            
              return false;
            }else{        
              //update transaction table
              transaction_created(Math.floor(Math.random() * 100) + 1);
  
              getAll(".submit_strip_from_btn").forEach((e) => {
                e.removeAttribute("disabled");
              });
              toast.success("Payment processed successfully. You should receive an email with your receipt shortly.");
  
              submitOrder.disabled = false;
              submitOrder.innerText = 'Submit';
  
              cardElement.clear();
              
              //Display transaction table
              getAll(".hide_sections").forEach((e) => {
                e.style.display = "none";
              });
              get("#Transaction_section").style.display = "block"; 
            }        
          })
        })
        .catch(async (e) => {
          if (e.data.message) {
            toast.error(e.data.message);
            submitOrder.disabled = false;
            submitOrder.innerText = 'Submit';          
            getAll(".submit_strip_from_btn").forEach((e) => {
              e.removeAttribute("disabled");
            });
          }
        }); 

      });    
       
    })
    .catch((e) => {
      if (e.response) {
        console.log(e.response)
        if (e.response.status == 500 || e.response.status == 400) toast.error(e.data.error);
        getAll(".submit_strip_from_btn").forEach((e) => {
          e.removeAttribute("disabled");
        });
      }
    });

  };

  const validateCard = (e) =>{
    let submitOrder = get('#submitOrder');
    var displayError = document.getElementById('card-errors');
    if (e.error) {
      displayError.textContent = e.error.message;
      submitOrder.innerText = 'Submit';
      //submitOrder.disabled = false;
    } else {
      displayError.textContent = '';
      submitOrder.innerText = 'Submit';
      submitOrder.disabled = false;
    }
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("userJimatech24")));
  }, []);

  return (
    <div className="col-12 col-md-12 col-lg-8 mb-2 border rounded border-success" 
      style={{height: '370px', marginTop: '45px', paddingTop: '20px'}}>
      <div className="row">
        <div className="col-12 d-flex justify-content-between p-2">
          <h5>Payment</h5>
          <div className="d-flex justify-content-around align-items-center">
            <i
              class="fab fa-cc-visa"
              style={{ fontSize: "1.5rem", margin: "0px 5px" }}
            ></i>
            <i
              class="fab fa-cc-mastercard"
              style={{ fontSize: "1.5rem", margin: "0px 5px" }}
            ></i>
          </div>
        </div>
        <div className="col-12">
          <p>
            We accept only debit/credit cards. 
            We do NOT store any card information.
            All payment information is encrypted and
            tokenized and not accessible by us or any other person.
          </p>
        </div>
        <div className="col-12">
          <CardElement className="form-control p-2" 
          onChange = {(e) => {
            validateCard(e)
          }}
          />
          <br></br>
          <span id="card-errors" className = "text-danger"></span>
        </div>
        <div className="col-12">
          <button
            className="d-none"
            id="strip_payment_send_btn"
            onClick={(e) => {
              makePayment();
            }}
          ></button>
        </div>

      </div>
    </div>
  );
};

const mapStateToPrps = (state) => {
  return { redux: state };
};
//send_amount_action
const mapDispatchToProps = (Dispatch) => {
  return {
 
    transaction_created:(newValue)=>  Dispatch(transaction_created(newValue))

  };
};
export default connect(mapStateToPrps,mapDispatchToProps)(StripForm);
