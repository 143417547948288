import React, { useState, useEffect } from "react";
import Axios from "axios";
import { serverURL } from "../../mainURL";
import { get, getAll } from "../../functions";
import DataTable from "../components/DataTable";
// redux
import { connect } from "react-redux";

const Transaction = (props) => {
    const [Transaction, setTransaction] = useState([]);
    async function GetTransaction(){
        console.log('transaction')

        const Transaction = await Axios.post(`${serverURL}/api/get/transaction`, {
            token: localStorage.getItem("token") || false,
        }).catch(error => {
            console.log(error)

           get('#logoutBtn').click();
        });
        
        let x = await Transaction.data.transaction.forEach((trans) => {
        if (trans.receive_amount) {
            let x = parseFloat(trans.receive_amount);
            trans.receive_amount = x.toFixed(2);
        }
        trans["name"] = `
        ${trans.receiver_first_name || ""} 
        ${trans.receiver_middel_name || ""}
        ${trans.receiver_last_name || ""}`;
        if (trans.type == 1) trans.type = "Electricity Top-Up ";
        if (trans.type == 2) trans.type = "Mobile Top-Up ";
        if (trans.receive_amount) trans.receive_amount = "GMD "+trans.receive_amount
        if (trans.currency)  {
            if (trans.send_amount) trans.send_amount = trans.currency+" "+ trans.send_amount
        }
        });
        
        setTransaction(Transaction.data.transaction);
    }

    useEffect(() => {     
        GetTransaction()              
    }, []);

    useEffect(() => {     
        GetTransaction()                    
    }, [props]);
        
    
    const cols = [
        {
        label: "Type",
        field: "type",
        },
        {
        label: "Name",
        field: "name",
        },
        {
        label: "Token",
        field: "gamswitch_token",
        },   
        {
            label: "Send Amount",
            field: "send_amount",
        },    
        {
            label: "Meter Number",
            field: "meter_reading",
        },
        {
            label: "Receive Amount",
            field: "receive_amount",
        },              
        {
        label: "Phone",
        field: "phone_number",
        },
        {
        label: "Country",
        field: "country",
        },
        {
        label: "Country Code",
        field: "countrycode",
        },
        {
        label: "City",
        field: "city",
        },
        {
        label: "Created At",
        field: "created_at",
        },
    ];

    return (
        <div
        id="Transaction_section"
        className="hide_sections px-3"
        style={{ display: "none" }}
        >
        <h5 className="text-center" >Transactions </h5>
        <div class="table-responsive">
            <DataTable col={cols} row={Transaction} />
    
        </div>
        </div>
    );
};

const mapStateToPrps = (state) => {
  return { redux: state };
};
export default connect(mapStateToPrps)(Transaction);
