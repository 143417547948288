import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { serverURL } from "../.././../mainURL";

class ForgotPasswordModal extends Component {
  state = {
    email: "",
  };
  sendEmail = () => {
    let email = this.state.email;
    // alert(email);
    axios
      .post(`${serverURL}/api/forget-password`, { email })
      .then((e) => {
        // console.log(e.data);
        localStorage.setItem(
          "reset_password",
          JSON.stringify({ id: e.data.id })
        );
        // hide modal
        let closeModal = document.getElementById("close-forget-password-modal");
        closeModal.click();
        toast.success(
          "We sent a code to you email. Please copy the code and enter in the form."
        );
        // open reset modal
        // ibrahimgamez@gmail.com

        document.getElementById("open-reset-modal").click();
      })
      .catch((e) => {
        toast.error("The email entered does not match our records.");
      });
  };
  componentWillMount() {}
  render() {
    return (
      <div>
        <a
          className="d-none"
          id="open-reset-modal"
          data-toggle="modal"
          data-target="#reset-password-modal"
        ></a>
        <div className="modal fade" id="forget-password-modal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content border-r-0">
              <div className="modal-header">
                <h5 className="modal-title">Forgot Password</h5>
                <button
                  type="button"
                  id="close-forget-password-modal"
                  className="close"
                  data-dismiss="modal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="w-100 row p-2">
                  <div className="col-12">
                    <label>Email</label>
                    <input
                      className="form-control border-r-0"
                      type="email"
                      onChange={(e) => {
                        this.state.email = e.target.value;
                      }}
                    />
                  </div>

                  <div className="col-12">
                    <button
                      className="btn btn-primary btn-main border-r-0 btn-md mt-3 d-block mx-auto"
                      onClick={(e) => {
                        this.sendEmail();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="col-12 d-flex justify-content-between alig-items-center">
                    <a
                      className="nav-link text-primary"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#login-modal"
                      style={{fontWeight:'700'}}
                    >
                      BACK
                    </a>
                    <a className="nav-link text-primary"> </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPasswordModal;
