import React, { Component, useState, useEffect } from "react";
import Axios from "axios";
import { get, getAll } from "../../functions";
import { ToastContainer, toast } from "react-toastify";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { serverURL } from "../../mainURL";

const StripForm2 = ({ BUYER_DATA, redux }) => {
  const [cvc, setcvc] = useState(0);
  const [message, setmessage] = useState("");
  const [expYear, setexpYear] = useState(0);
  const [expMonth, setexpMonth] = useState(0);
  const [cardNumber, setcardNumber] = useState(0);
  const [formProcess, setformProcess] = useState(0);
  useEffect(() => {
    loadStripe();
  }, []);
  const elements = useElements();
  const stripe = useStripe();
  const pay = (e) => {
    setformProcess(1);
    window.Stripe.card.createToken(
      {
        number: cardNumber,
        exp_month: expMonth,
        exp_year: expYear,
        cvc: cvc,
      },
      async (status, response) => {
        if (status === 200) {
          setmessage(`Success! Card token ${response.card.id}.`);
          setformProcess(false);
          const payment_intent = await Axios.post(
            `${serverURL}/api/payment-intent`,
            {
              amount: redux.send_amount.toFixed(2) * 100,
              BUYER_DATA,
            }
          )
            .then(async (e) => {
              console.log(e.data.clientSecret);

              const confirm = await stripe.confirmCardPayment(
                e.data.clientSecret,

                {
                  payment_method: {
                    card: "cardElement",
                    billing_details: {
                      name: "Jenny Rosen",
                    },
                  },
                }
              );
              console.log(confirm);
              // const paymentMethodReq = await stripe.createPaymentMethod({
              // type: "card",
              // card: {},
              // billing_details: {
              //     name: `${BUYER_DATA.ReceiverFirstName} ${BUYER_DATA.ReceiverMiddleName} ${BUYER_DATA.ReceiverLastName}`,
              //     email: BUYER_DATA.user_email,
              //     address: {
              //     city: BUYER_DATA.City,
              //     // country: BUYER_DATA.Country,
              //     },
              // },
              // });
              // console.log(paymentMethodReq.paymentMethod.id);
              // console.log();
              // console.log(e.data);
              // const confiremCardPayment = await stripe.confirmCardPayment(
              // e.data.clientSecret,
              // {
              //     payment_method: paymentMethodReq.paymentMethod.id,
              // }
              // );

              getAll(".submit_strip_from_btn").forEach((e) => {
                e.removeAttribute("disabled");
              });
              toast.success("Payment done.");
            })
            .catch((e) => {
              if (e.response) {
                if (e.response.status == 500)
                  toast.error(e.response.data.message);
                getAll(".submit_strip_from_btn").forEach((e) => {
                  e.removeAttribute("disabled");
                });
              }
            });
        } else {
          setmessage(response.error.message);
          setformProcess(false);
        }
      }
    );
  };

  const loadStripe = () => {
    if (!window.document.getElementById("stripe-script")) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window["Stripe"].setPublishableKey(
          "pk_test_51H8qIkLAbz9gH2bFs7Jj2kU6LFT89L7txpGMIyZyg50GIDoKRILzp7uBbY72204PRivxpzm7cKC2oN4ytG6oznEG00nEjo2JvI"
        );
      };
      window.document.body.appendChild(s);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-md-8 mr-auto ml-auto">
          <div className="panel panel-default">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                pay();
              }}
            >
              <div className="panel-body mt-5">
                <h4>Stripe - Custom form demo</h4>
                <p>
                  <b>{message}</b>
                </p>
                <div className="row">
                  <div className="col-xs-12 col-md-12">
                    <div className="form-group">
                      <label>CARD NUMBER</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Valid Card Number"
                          name="cardNumber"
                          maxLength="18"
                          onChange={(e) => setcardNumber(e.target.value)}
                        />
                        <span className="input-group-addon">
                          <span className="fa fa-credit-card"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-7 col-md-7">
                    <div className="form-group">
                      <label>
                        <span className="hidden-xs">EXPIRATION</span> Date
                      </label>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <select
                              name="expMonth"
                              className="form-control"
                              onChange={(e) => setexpMonth(e.target.value)}
                            >
                              <option value="">Select Month</option>
                              <option value="1">01</option>
                              <option value="2">02</option>
                              <option value="3">03</option>
                              <option value="4">04</option>
                              <option value="5">05</option>
                              <option value="6">06</option>
                              <option value="7">07</option>
                              <option value="8">08</option>
                              <option value="9">09</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 pull-right">
                          <div className="form-group">
                            <select
                              name="expYear"
                              className="form-control"
                              onChange={(e) => setexpYear(e.target.value)}
                            >
                              <option value="">Select Year</option>
                              <option value="20">2020</option>
                              <option value="21">2021</option>
                              <option value="22">2022</option>
                              <option value="23">2023</option>
                              <option value="24">2024</option>
                              <option value="25">2025</option>
                              <option value="26">2026</option>
                              <option value="27">2027</option>
                              <option value="28">2028</option>
                              <option value="29">2029</option>
                              <option value="30">2030</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-5 col-md-5 pull-right">
                    <div className="form-group">
                      <label>CVV CODE</label>
                      <input
                        type="text"
                        name="cvv"
                        className="form-control"
                        placeholder="CVC"
                        maxLength="4"
                        onChange={(e) => setcvc(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-footer">
                <div className="row">
                  <div className="col-xs-12 col-md-12">
                    {formProcess ? (
                      <span className="btn btn-warning btn-lg btn-block">
                        Please wait...
                      </span>
                    ) : (
                      <button className="btn btn-warning btn-lg btn-block">
                        Process payment
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
            <p className="mt-2">
              Try it out using the test card number 4242 4242 4242 4242, a
              random three-digit CVC number and any expiration date in the
              future.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const MSTP = (state) => {
  return { redux: state };
};

export default connect(MSTP)(StripForm2);
