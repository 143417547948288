import React, { Component } from "react";

import NavBar from "../components/navbar/NavBar";

import "./home.css";
import ContactUs from "../components/ContactUs";
import UpdateProfile from "../components/UpdateProfile";
import SystemSetting from "../components/SystemSetting";
import ElectricityTopUp from "../components/ElectricityTopUp";
import MobileTopUp from "../components/MobileTopUp";
import { openModal, closeModal, hideElements, getAll } from "../../functions";
import { ToastContainer, toast } from "react-toastify";
// redux

import { connect } from "react-redux";
import $ from "jquery";
import CookiesAlert from "../components/CookiesAlert";
import Transaction from "../components/Transaction";
import Users from "../components/Users";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: false,
    };
  }
  componentDidMount() {
    $(document).ready((e) => {
      $(".ElectricityTopUp_section,.MobileTopUp_section").hide();
    });

    this.setState({ user: localStorage.getItem("userJimatech24") || false });
  }

  shopNow(){
    if (!this.state.user) {
      openModal("#login-modal");
      hideElements(".hide_sections");
      getAll(".show_section").forEach((e) => {
        e.style.display = "block";
      });
    }else{
      let v = document.getElementById("ShopNowSelect");
      if (v.value == 1) {
        $(".hide_sections").hide();
        $(".ElectricityTopUp_section").show();
      }
      if (v.value == 2) {
        $(".hide_sections").hide();
        $(".MobileTopUp_section").show();
      }                  
    }    
  }

  render() {
    return (
      <div>
        <ToastContainer />
        {/* Nav bar  */}
        <section className="mx-auto">
          <NavBar />
        </section>
        {/* Home image */}
        <section className="main-home-img">
            <img src="./assets/home.jpg" />
            <div class="header-text">
              ONLINE 24/7 HOURS<br />Send Love Back Home
            </div>
        </section>
        {/* Content section */}
        <section className="contant-section">
          <div className="service" placeholder="Select Service">
            <select
              id="ShopNowSelect"
              className="form-select-lg p-3 mr-2 w-50"
              style={{ height: "59px" }}
            >
              <option selected value="1">
                Electricity Top-Up{" "}
              </option>
              <option value="2">Mobile Top-Up</option>
            </select>
            <button
              className="btn btn-primary btn-main p-3"
              id="btn-shop-now"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ user: localStorage.getItem("userJimatech24") || false }, this.shopNow());
              }}
            >
              Shop Now
            </button>
          </div>
        </section>
        <section className="system_setting_section hide_sections">
          <SystemSetting />
        </section>
        <section className="update_profile_section hide_sections">
          <UpdateProfile />
        </section>
        {this.state.user ? (
          <section>
            <ElectricityTopUp />
          </section>
        ) : (
          ""
        )}

        {this.state.user ? (
          <section>
            <Transaction />
          </section>
        ) : (
          ""
        )}

        {this.state.user ? (
          <section>
            <Users />
          </section>
        ) : (
          ""
        )}  

        {this.state.user ? (
          <section>
            <MobileTopUp />
          </section>
        ) : (
          ""
        )}
        {/* Weclome Section  */}

        <div className="welcome-section show_section hide_sections">
          <h1>Welcome to Jimatech Solutions</h1>

          {/* <h1>{this.props.redux_state.auth}</h1>
          <button
            className="btn btn-outline-danger bg-lg"
            onClick={(e) => {
              this.props.changeHeading("new Heading assign to gloable state");
            }}
          >
            Change Sate
          </button> */}
          <p>
          Welcome to Jimatech! Your number one stop for all your electricity-cash power and mobile top-up credit needs. Send cash power or 
          mobile credit to anyone in The Gambia from anywhere in the world at any time without leaving your room. All you need is a valid credit 
          card or debit card number, meter number, and a phone number and we do the rest! Our services are affordable, reliable and fast.
          </p>
        </div>
        {/* our Services Section  */}
        <div className="services-section show_section hide_sections">
          <h1>Our Services</h1>
          <div className="row mx-auto px-4">
            <div className="col-12 mt-2  col-md-6 col-lg-6 d-flex justify-content-center flex-column text-center">
              <i className="fas fa-bolt" style={{color: '#efc959'}}></i>
              <h5>Electricity Top-Up</h5>
              <p>
              We partner with NAWEC to bring you all your electricity needs. We provide online services 24 hours a day, 7 days a week and
               also have offices throughout The Gambia. So, don’t let yourself or your love ones stay in the dark! Visit our website and send 
               some love back home or stop in one of our offices and we’ll help you light up!
              </p>
            </div>
            <div className="col-12 mt-2  col-md-6 col-lg-6 d-flex justify-content-center flex-column text-center">
              <i className="fas fa-mobile-alt" style={{color: '#4ba6ff'}}></i>
              <h5>Mobile Top-Up</h5>
              <p>
              We partner with all GMS companies in The Gambia to make it easier for you to send Mobile Top-Up credit to yourself or anyone in 
              The Gambia. Please visit our website at anytime or our offices and we’ll help you recredit your phone or send your love ones in 
              The Gambia mobile credit so, they can continue communicating.
              </p>
            </div>
          </div>
        </div>
        {/* Contact Us  */}

        <section className="contact_us_section show_section hide_sections">
          <ContactUs />
        </section>
        <section
          className="privacy_section  hide_sections px-3"
          style={{ display: "none" }}
        >
          <h5 className="text-center">Privacy Policy</h5>
          <p className="px-5 mt-2">
          The information Jimatech Solutions, LLC (hereinafter referred to as “Jimatech”) collects is used in a variety of ways, including: (I) internal business analysis (II) to improve the site and services; (III) to notify you about updates to the site and services; (IV) to let you know about products, services, and promotions that you may be interested in; (V) for our marketing purposes; (VI) to prevent fraud; and (VII) for internal review.<br></br><br></br>
          We are not responsible for the content or privacy practices on any web site not operated by Jimatech to which Jimatech links or that links to Jimatech.<br></br><br></br>
          We do not disclose personal information to third parties, except when one or more of the following conditions is true: (I) we have your permission to make the disclosure; (II) the disclosure is consistent with the purpose for which the personal information was obtained; (III) the disclosure is for financial service providers in order to fulfill and carry out the purchase and provision of goods and services requested by you; (IV) the disclosure is permitted by relevant law; (V) the personal information to be disclosed is publicly available; (VI) the disclosure is reasonably related to the sale or other disposition of all or part of our business or assets; (VII) the disclosure is for our own marketing purposes, or, with your authorization, for the marketing purposes of a select and very limited number of third parties; (VIII) the disclosure is in our sole discretion necessary for the establishment or maintenance of legal claims or legal compliance, to satisfy any law, regulation, subpoena or government request, or in connection with litigation; (IX) the disclosure is in our sole discretion about users who we believe are engaged in illegal activities or are otherwise in violation of our terms and conditions of use, even without a subpoena, warrant or court order; or (X) the disclosure is for outside businesses to perform certain services for us, such as administrative services.<br></br><br></br>
          Prior to sharing of your personal information with others, agreements are made between Jimatech and such outside parties to whom we are transferring data. If Jimatech becomes aware that any such outside party is improperly using or disclosing Personal Information, it will notify that outside party and request that it take reasonable steps to cease such use or disclosure.<br></br><br></br>
          Jimatech incorporates physical, electronic, and administrative procedures to safeguard the confidentiality of your personal information, including Secure Sockets Layer (“SSL”) for all financial transactions through the Site. We use SSL encryption to protect your personal information online, and we also take several steps to protect your personal information in our facilities. Access to your personal information is restricted.<br></br><br></br>
          Only employees who need access to your personal information to perform a specific job are granted access to your personal information. Finally, we may rely on third-party service providers for the physical security of some of our computer hardware.<br></br><br></br>
          While we use industry-standard precautions to safeguard your personal information, we cannot guarantee complete security. 100% complete security does not presently exist anywhere online or offline.<br></br><br></br>
          If we change or update this Privacy Policy, we will post changes and updates on the Website so that you will always be aware of what information we collect, use and disclose. We encourage you to review this Privacy Policy from time to time so you will know if the Privacy Policy has been changed or updated. If you have any questions about the Privacy Policy, please contact us.

          </p>
        </section>

        <section
          className="cookies_section  hide_sections px-3"
          style={{ display: "none" }}
        >
          <h5 className="text-center">Cookies Policy</h5>
          <p className="px-5 mt-2">
          Thank you for visiting jimatech24.com ("Website"). This cookie notice applies to the Website, any website or branded pages on third
          party platforms (e.g. Youtube, Facebook, Twitter) and applications accessed or used through such websites or platforms operated by or on 
          behalf of Jimatech Solutions.<br></br><br></br>
          By using the Website, you are consenting to our use of cookies and other tracking technology in accordance with this notice. If you 
          do not agree to our use of cookies and other tracking technology in this way, you should not use the Website. If you disable 
          cookies that we use, this may impact your user experience while on the Website.<br></br><br></br>

          <b>1. What are cookies?</b><br></br>
          Cookies are small text files that are placed on your computer by websites that you visit. They are widely used in order to make 
          websites work, or work more efficiently, as well as to provide information to the owners of the site. <br></br><br></br>

          <b>2. How and why we use them?</b><br></br>
          The Website only uses cookies essential for the correct operation of the website.<br></br><br></br>

          <b>3. What type of cookies does the Website use?</b><br></br>
          The following types of cookies are used on the Website.<br></br>
          <b> - Necessary cookies</b><br></br>
          These are cookies that are strictly necessary for the operation of a website. Without these cookies, this website won’t work properly.<br></br><br></br>
          </p>
        </section>


        <section
          className="TERMS_section  hide_sections px-3"
          style={{ display: "none" }}
        >
          <h5 className="text-center">Terms and Conditions</h5>
          <p className="px-5 mt-2">
          If you choose to continue to browse and use the Jimatech solutions website (hereinafter, Jimatech, or Jimatech24.com), you are agreeing to comply with and be bound by the following terms and conditions of use, as well as any and all disclaimers of warranties and liability (hereinafter referred to as the “Agreement”). You do not have to agree to these terms and conditions, however, if you do not agree to be bound with any part of these terms and conditions or disclaimers, you will not be permitted to use this website or services of Jimatech.<br></br><br></br>
          This Agreement is effective upon acceptance by new users. You accept this Agreement by clicking the Submit button when registering a Jimatech account; accessing or using Jimatech, or its affiliates’, sites, service, and tools; or as otherwise indicated on a specific site, service, or tool.<br></br><br></br>
          You’re eighteen (18) years of age or older.<br></br><br></br>
          Please be advised: this Agreement contains provisions that govern how claims you and we have against each other are resolved. It also contains an Agreement to Arbitrate. Unless you opt-out: (I) you will only be permitted to pursue claims against Jimatech and its affiliates (including, but not limited to, Jimatech, LLC, and the individual members or managers) on an individual basis, not as part of any class or representative action or proceeding, and (II) you will only be permitted to seek relief (including monetary, injunctive, and declaratory relief) on an individual basis.<br></br><br></br>
          Before you become a member of the Jimatech community, you must read and accept all of the terms in, and linked to, this Agreement and the Jimatech Privacy Policy. We strongly recommend that, as you read this Agreement, you also access and read any linked information. By accepting this Agreement, you agree that this Agreement and Privacy Policy will apply any time you use Jimatech and its affiliates’ sites or services, or when you use the tools that are made available to interact with Jimatech sites and services.<br></br><br></br>
          You are required to comply with, and to ensure compliance with, all laws, ordinances, and regulations applicable to your activities on Jimatech.<br></br><br></br>
          INTELLECTUAL PROPERTY<br></br>
          All text, graphics, button icons, images, audio clips, and software (collectively, "Content"), belongs exclusively to Jimatech, or its affiliates. The collection, arrangement, and assembly of all Content on Jimatech (the "Compilation") belongs exclusively to Jimatech or its affiliates. All software used on Jimatech (the "Software") is the property of Jimatech, its affiliates or its software suppliers. The Content, the Compilation and the software are all protected by U.S. and international copyright laws. Jimatech Solutions, Jimatech24.com and other logos, slogans, trade names or words are registered trademarks, or service marks of Jimatech, LLC, its affiliates, suppliers, or third parties. The use of any of our trademarks or service marks without our express written consent is strictly prohibited. You may not use our trademarks or service marks in connection with any product or service in any way that is likely to cause confusion. You may not use our trademarks or service marks in any manner that disparages or discredits us. You may not use any of our trademarks or service marks in meta tags without prior explicit consent.<br></br><br></br>
          USE OF SITE<br></br>
          This Agreement grants you a limited, revocable, nonexclusive license to access Jimatechsolutions.com or Jimatech24.com and use its sites, tools, and services, in whole or in part, including but not limited to Jimatech24.com and Jimatech Solutions, LLC intellectual property therein, solely in compliance with this Agreement. This license does not include any resale or commercial use of Jimatech, or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of any services of Jimatech or its contents; any downloading, copying, or other use of account information for the benefit of any third party; or any use of data mining, robots, or similar data gathering and extraction tools. All rights not expressly granted to you in this Agreement are reserved and retained by Jimatech or its licensors, suppliers, publishers, vendors, or other content providers. No part of any Jimatech website or service may be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of Jimatech. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of Jimatech without express written consent. You may not use any meta tags or any other "hidden text" utilizing Jimatech’s name or trademarks without the express written consent of Jimatech. You may use Jimatech only as permitted by law. The licenses granted by Jimatech terminate if you do not comply with this Agreement.<br></br><br></br>
          Product representations expressed on Jimatech are that of the vendor and are not made by Jimatech. Jimatech has the right, but not the obligation, to regulate content posted to, stored on or transmitted via Jimatech by any user (or any other third party in any manner); to regulate conduct (including but not limited to any authorized or unauthorized access to or use of Jimatech) by any user (or any other third party in any manner); and to enforce this Agreement, for any reason and in any manner or by any means that Jimatech, in its sole discretion, deems necessary or appropriate. Jimatech may, in its sole discretion and without notice, start, stop or modify any regulation or enforcement measures at any time.<br></br><br></br>
          ACCOUNT ACCESS AND ELECTRONIC COMMUNICATION<br></br>
          We may assign you a password and account identification to enable you to access and use certain portions of Jimatech. Each time you use a password or identification, you will be deemed to be authorized to access and use Jimatech in a manner consistent with the terms and conditions of this Agreement, and we have no obligation to investigate the authorization or source of any such access or use of Jimatech. YOU WILL BE SOLELY RESPONSIBLE FOR ALL ACCESS TO AND USE OF THIS SITE BY ANYONE USING THE PASSWORD AND IDENTIFICATION ORIGINALLY ASSIGNED TO YOU WHETHER OR NOT SUCH ACCESS TO AND USE OF THIS SITE IS ACTUALLY AUTHORIZED BY YOU, INCLUDING WITHOUT LIMITATION, ALL COMMUNICATIONS AND TRANSMISSIONS AND ALL OBLIGATIONS (INCLUDING WITHOUT LIMITATION FINANCIAL OBLIGATIONS) INCURRED THROUGH SUCH ACCESS OR USE. You are solely responsible for protecting the security and confidentiality of the password and identification assigned to you. You shall immediately notify us of any unauthorized use of your password or identification or any other breach or threatened breach of this Site's security.
          When you use Jimatech, or send e-mails to us, you are communicating with us electronically, you consent to receive communications from us electronically. We will communicate with you by e-mail, phone, text message, push mobile notifications, or by posting notices on Jimatech, social media platforms, or through our other services. You agree that all agreements, notices, disclosures and other communication that we provide to you electronically satisfy any legal requirements that such communications be in writing.
          <br></br><br></br>
          LIMITATION OF LIABILITY<br></br><br></br>
          Disclaimer of Endorsement<br></br>
          The listing or display of materials or products of any business, organization, association, or other entity or product or services thereof (hereinafter referred to as the “displayed entities”) on this website does not constitute an endorsement by Jimatech, or any of the individual members or managers of said entities. Moreover, the inclusion of any business, organization, association, or other entity on this website does not indicate in any manner that Jimatech or its affiliates support, sanction, or verify the information of such entities. Furthermore, through this website you are able to link to other websites over which we have no control. The inclusion of any links does not imply a recommendation or endorse the views expressed within them.
          Disclaimer of Formation of Agency Relationship<br></br>
          No agency, partnership, joint venture, employee-employer, or franchiser-franchisee relationship is intended or created by this Agreement.
          <br></br><br></br>
          Disclaimer of Omissions<br></br>
          The product and service descriptions on this website are intended as an informational tool only and is being made available as a service. The information and materials contained on this website may contain inaccuracies, errors, or omissions. Jimatech and its affiliates expressly disclaim any responsibility or liability for any such inaccuracies, errors or omissions on this website to the fullest extent permitted by law.
          <br></br><br></br>
          Disclaimer of Unavailability<br></br>
          Every effort is made to keep this website up and running smoothly. However, Jimatech, and any of the individual members or managers take no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.<br></br><br></br>
          General Disclaimer and Limitation of Liability<br></br>
          You agree that you are making use of the sites, services, products and tools at your own risk, and that they are being provided to you on an “AS IS” and “AS AVAILABLE” basis.
          Accordingly, to the extent permitted by applicable law, Jimatech and its affiliates disclaims all express or implied warranties, terms and conditions including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement. In addition, to the extent permitted by applicable law, we are not liable, and you agree not to hold Jimatech and its affiliates responsible, for any damages or losses resulting directly or indirectly from: (I) your use of or your inability to use our sites, services, products and tools; (II) delays or disruptions in our sites, services, or tools; (III) viruses or other malicious software obtained by accessing our sites, services, or tools, or any site, service, or tool linked to our sites, services, or tools; (IV) glitches, bugs, errors, or inaccuracies of any kind in our sites, services, and tools or in the information and graphics obtained from them; (V) service or product delivery errors due to information you provided in your order (VI) the content, actions, or inactions of third parties; and/or (VII) a suspension or other action taken with respect to your account. You expressly agree that your use of the Jimatech services and products is at your sole risk.<br></br><br></br>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS WEBSITE, INCLUDING, BUT NOT LIMITED TO INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY, SPECIAL OR CONSEQUENTIAL DAMAGES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OUR TOTAL LIABILITY TO YOU FOR ANY DAMAGES (REGARDLESS OF THE FOUNDATION FOR THE ACTION) SHALL NOT EXCEED IN THE AGGREGATE THE AMOUNT OF FEES ACTUALLY PAID BY YOU TO US DURING THE MONTH IMMEDIATELY PRECEDING THE ACT ALLEGEDLY GIVING RISE TO OUR LIABILITY.<br></br><br></br>
          Indemnity<br></br>
          You will indemnify and hold Jimatech, its affiliates and subsidiaries, and their respective officers, directors, members, managers, employees, and agents, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of your breach of this Agreement, your improper use of Jimatech’s sites, services, or tools, and/or your violation of any law or the rights of a third party.<br></br><br></br>
          LEGAL DISPUTES<br></br>
          You and Jimatech (and our affiliates and subsidiaries, and our and their respective officers, directors, members, managers, employees, and agents) agree that any claim or dispute at law or equity that has arisen or may arise between us will be resolved in accordance with the provisions set forth in this Legal Disputes Section. Please read this Section carefully. It affects your rights and will have a substantial impact on how claims you and we have against each other are resolved.<br></br><br></br>
          Applicable Law<br></br>
          You agree that the laws of The Gambia, without regard to principles of conflict of laws, will govern this Agreement and any claim or dispute that has arisen or may arise between you and Jimatech (and our affiliates), except as otherwise stated in this Agreement.<br></br><br></br>
          Agreement to Arbitrate<br></br>
          You and Jimatech (and its affiliates) each agree that any and all disputes or claims that have arisen or may arise between you and Jimatech (and its affiliates) shall be resolved through arbitration.<br></br><br></br>
          Opt-Out Procedure<br></br>
          You can choose to reject this Agreement to Arbitrate (“opt-out”) by mailing us a written opt-out notice (“Opt-Out Notice”). The Opt-Out Notice must be postmarked no later than 30 days after the date you accept the Agreement for the first time. You must mail the Opt-Out Notice to Jimatech Solutions, Kombo Coastal Road, Serekunda, The Gambia. The Opt-Out Notice must state that you do not agree to this Agreement to Arbitrate and must include your name, address, and the user ID(s) and email address(es) associated with the Jimatech account(s) to which the opt-out applies. You must sign the Opt-Out Notice for it to be effective. This procedure is the only way you can opt-out of the Agreement to Arbitrate. If you opt-out of the Agreement to Arbitrate, all other parts of the Agreement and its Legal Disputes Section will continue to apply to you. Opting out of this Agreement to Arbitrate has no effect on any previous, other, or future arbitration agreements that you may have with us.<br></br><br></br>
          Future Changes to the Agreement to Arbitrate<br></br>
          Notwithstanding any provision in the Agreement to the contrary, you and Jimatech agree that if Jimatech or its affiliates make any change to this Agreement to Arbitrate (other than a change to any notice address or website link provided herein) in the future, that change shall not apply to any claim of which you provided Jimatech with written notice prior to the effective date of the change. Moreover, if we seek to terminate the Agreement to Arbitrate as included in the Agreement, any such termination shall not be effective until 30 days after the version of the Agreement not containing the Agreement to Arbitrate is posted on Jimatech24.com and shall not be effective as to any claim of which you provided Jimatech with written notice prior to the date of termination.<br></br><br></br>
          Judicial Forum for Legal Disputes<br></br>
          Unless you and Jimatech or its affiliates agree otherwise, in the event that the Agreement to Arbitrate above is found not to apply to you or to a particular claim or dispute, either as a result of your decision to opt-out of the Agreement to Arbitrate or a court order, you agree that any claim or dispute that has arisen or may arise between you and Jimatech must be resolved exclusively by a Gambian court. You and Jimatech agree to submit to the personal jurisdiction of the courts located in The Gambia for the purpose of litigating all such claims or disputes.<br></br><br></br>
          ADDITIONAL TERMS AND CONDITIONS OF USE<br></br>
          The content of this website is for your general information and use only. It is subject to change at any time without notice.<br></br><br></br>
          Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. It shall be your own responsibility to ensure that any products, services, or information available through this website meet your specific requirements.
          This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited other than in accordance with copyright notice. <br></br><br></br>
          Unauthorized use of this website may give rise to a claim for damages and may constitute a criminal offense.<br></br><br></br>
          Unless stated otherwise in this Agreement, if any of the provisions in this Agreement shall be deemed invalid, void, or for any reason unenforceable, such provisions shall be deemed severable and shall not affect the validity and enforceability of any remaining provision. Headings are for reference purposes only and do not limit the scope or extent of such Section. Our failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches. We do not guarantee we will take action against all breaches of this Agreement.<br></br><br></br>
          We may amend this Agreement at any time by posting the amended terms on jimatech24.com Our right to amend the Agreement includes the right to modify, add to, or remove terms in the Agreement. Except as stated otherwise in this Agreement or elsewhere, all amended terms shall automatically be effective 30 days after they are initially posted. This Agreement may not be otherwise amended except in a writing hand signed by you and us. For purposes of this provision, a “writing” does not include an email message and a signature does not include an electronic signature.<br></br><br></br>
          This Agreement (including incorporated policies and rules) sets forth the entire understanding and agreement between us with respect to the subject matter hereof. The following Sections survive any termination of this Agreement: Release, Content, Limitation of Liability, Indemnity and Legal Disputes.
          </p>
        </section>

        <section className="section-footer mt-4"  >
          <ul>
            <li>
              <a
                onClick={(e) => {
                  $(".hide_sections").slideUp();
                  $(".privacy_section").slideDown();
                }}
              >
                PRIVACY
              </a>
            </li>
            <li>|</li>
            <li>
              <a
                onClick={(e) => {
                  $(".hide_sections").slideUp();
                  $(".TERMS_section").slideDown();
                }}
              >
                TERMS
              </a>
            </li>
            <li>|</li>
            <li>
              <a
                onClick={(e) => {
                  $(".hide_sections").slideUp();
                  $(".cookies_section").slideDown();
                }}
              >
                COOKIES
              </a>
            </li>            
          </ul>
        </section>
        {localStorage.getItem("cookies_yes") ? "" : <CookiesAlert />}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    redux_state: state,
  };
};

export default connect(mapStateToProps)(Home);
