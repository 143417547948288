import React, { Component } from "react";
import LoginModal from "../Modal/LoginModal";
import RegisterModal from "../Modal/RegisterModal";
import ForgotPasswordModal from "../Modal/ForgotPasswordModal";
import ResetPassword from "../Modal/ResetPassword";
import { hideElements, get, getAll } from "../../../functions";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: false,
      user: {},
    };
  }
  scroll = () => {
    let aboutus = document.querySelector("#aboutus");
    let services = document.querySelector("#services");
    let contactus = document.querySelector("#contactus");
    // let login = document.querySelector("#login");
    let x = [
      [aboutus, ".welcome-section"],
      [services, ".services-section"],
      [contactus, ".contact-us-section"],
    ];
    x.forEach((e) => {
      let target = e[1];

      e[0].addEventListener("click", (e) => {
        let x = document.querySelector(target);
        x.scrollIntoView({ behavior: "smooth", block: "center" });
      });
    });
  };
  checkIfAuth() {
    if (localStorage.getItem("userJimatech24")) {
      // console.log(JSON.parse(localStorage.getItem("user")));
      this.setState({
        auth: true,
        user: JSON.parse(localStorage.getItem("userJimatech24")),
      });
    }
  }
  componentDidMount() {
    this.scroll();
    this.checkIfAuth();
    this.hideMenu();
    this.toggleMenu();
  }
  logout = () => {
    this.setState({ auth: false, user: {} });
    localStorage.removeItem("userJimatech24");
    localStorage.removeItem("token");
  };
  settingClick = () => {
    // setting-section-form
    let ele = get("#setting-section-form");
    ele.style.display = "flex";

    hideElements(".hide_sections");
    get(".system_setting_section").style.display = "block";
    ele.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    get(".navbar-toggler").click();
  };
  profileClick = () => {
    let ele = get("#update-profile-user-form");
    ele.style.display = "flex";

    hideElements(".hide_sections");
    get(".update_profile_section").style.display = "block";
    ele.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    get(".navbar-toggler").click();
  };
  transactionsClick = () =>{
    hideElements(".hide_sections");    
    get("#Transaction_section").style.display = "block";
    let ele = get("#Transaction_section");
    ele.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    get(".navbar-toggler").click();
  };

  usersClick = () =>{
    hideElements(".hide_sections");    
    get("#users_section").style.display = "block";
    let ele = get("#users_section");
    ele.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    get(".navbar-toggler").click();
  };

  showSection(event) {
    hideElements(".hide_sections");
    getAll(".show_section").forEach((e) => {
      e.style.display = "block";
    });
    event.target.click();
    // console.log();
  }
  hideMenu = () => {
    // alert(1);
    getAll(".navbar .nav-link-item").forEach((e) => {
      e.addEventListener("click", () => {
        get(".navbar-toggler").click();
        getAll(".menu-bg").forEach((e) => {
          e.remove();
        });
      });
    });
  };
  toggleMenu = () => {
    get(".navbar-toggler").addEventListener("click", (e) => {
      let root = get("#root");
      let toggler = get(".navbar-toggler");
      let div = document.createElement("div");
      div.setAttribute("class", "menu-bg");

      root.insertBefore(div, root.firstChild);
      div.addEventListener("click", () => {
        toggler.click();
        getAll(".menu-bg").forEach((e) => {
          e.remove();
        });
      });
      // console.log(root);
    });
  };

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-md fixed-top navbar-light bg-white p-0 ">
          <div className="container py-0">
            <a className="navbar-brand " href="#"
            onClick={(e) => {
              this.showSection(e);
            }}
            >
              <img src="./assets/logo.png" id="logo"/>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#jimtechNavBar"
              onClick={(e) => {
                let div = getAll(".menu-bg");
                // console.log(div.length);
                if (div.length > 1) {
                  div.forEach((e) => e.remove());
                }
              }}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse  navbar-collapse" id="jimtechNavBar">
              <div className="navbar-nav align-items-lg-center align-items-md-center ml-auto py-4">
                <a
                  className="nav-link nav-link-item"
                  id="aboutus"
                  onClick={(e) => {
                    this.showSection(e);
                  }}
                >
                  ABOUT US
                </a>
                <a
                  className="nav-link nav-link-item"
                  id="services"
                  onClick={(e) => {
                    this.showSection(e);
                  }}
                >
                  SERVICES
                </a>
                <a
                  className="nav-link nav-link-item"
                  id="contactus"
                  onClick={(e) => {
                    this.showSection(e);
                  }}
                >
                  CONTACT US
                </a>

                {!this.state.auth ? (
                  <a
                    className="nav-link nav-link-item"
                    data-toggle="modal"
                    data-target="#login-modal"
                  >
                    LOGIN
                  </a>
                ) : null}
                {this.state.auth ? (
                  <div class="dropdown">
                    <a
                      class="nav-link nav-link-item dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      MY ACCOUNT
                    </a>
                    <div class="dropdown-menu">
                      <a
                        class="dropdown-item"
                        onClick={(e) => {
                          this.transactionsClick();
                        }}
                      >
                        TRANSACTIONS
                      </a>
                      {this.state.user.level == 1 ? (
                      <a
                        class="dropdown-item"
                        onClick={(e) => {
                          this.usersClick();
                        }}
                      >
                        USERS
                      </a>   
                      ) : (
                        ""
                      )}                                         
                      <a
                        class="dropdown-item"
                        onClick={(e) => {
                          this.profileClick();
                        }}
                      >
                        PROFILE
                      </a>
                      {this.state.user.level == 1 ? (
                        <a
                          class="dropdown-item"
                          onClick={(e) => {
                            this.settingClick();
                          }}
                        >
                          SETTINGS
                        </a>
                      ) : (
                        ""
                      )}

                      <a
                        className="dropdown-item"
                        id="logoutBtn"
                        onClick={(e) => {
                          this.showSection(e);
                          this.logout();
                        }}
                      >
                        LOGOUT
                      </a>
                    </div>
                  </div>
                ) : null}
                <a className="nav-link pl-5">
                  <span style={{fontSize: '15px'}}>Currency:</span>
                  <select
                    className="form-select text-muted border-0 select-currency-navbar "
                    id="select-currency-navbar"
                    style={{
                      padding: "0px 10px",
                      boxShadow: "none",
                      cursor: "pointer",
                    }}
                    // onChange={}
                  >
                    <option defaultValue value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                  </select>
                </a>
              </div>
            </div>
          </div>
        </nav>
        {!this.auth ? <LoginModal /> : null}
        {!this.auth ? <RegisterModal /> : null}
        {!this.auth ? <ForgotPasswordModal /> : null}
        {!this.auth ? <ResetPassword /> : null}
      </div>
    );
  }
}

export default NavBar;
