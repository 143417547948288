import { CHANGE_TRANSACTION_FEE } from "../actions/type";
import { SEND_AMOUNT_CHANGE } from "../actions/type";
import { TRANSACTION_CREATED } from "../actions/type";

export default function createReducer(state, action) {
  switch (action.type) {
    case CHANGE_TRANSACTION_FEE:
      return {
        ...state,
        transaction_fee: action.newValue,
      };
    case SEND_AMOUNT_CHANGE:
      return {
        ...state,
        send_amount: action.newValue,
      };
    case TRANSACTION_CREATED:
      return {
        ...state,
        transaction_created: action.newValue ,
      };
    default:
      return state;
  }
}
